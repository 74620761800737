import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.css';


const AddForm = (props) =>{
  
  var output=(
    <form>
      <p>Posts need to be refreshed for Live CVC to be updated, see the actual post for likes, comment and viewcount</p>
      <input type="text" name="newstreaminput" id="newstreaminput" placeholder="https://www.facebook.com/mypage/videos/713048955934560"/>
      <button onClick={props.addStream} value="addstream" >Add Stream</button>
    </form>
  );


  return output;
}

const StreamList = (props) =>{
  
  var output=(
    <div>
    <ol className="streamlist">     
   
    </ol>
    </div>
  );

  return output;
}

//End components

class MultiView extends Component {
  

  constructor(props) {
    super(props);
    this.state = { width:'350' };
    // Don't call this.setState() here!
    //max750
    this.refreshSingle = this.refreshSingle.bind(this);
    this.addStream = this.addStream.bind(this);
    this.clear = this.clear.bind(this);
  }
  componentDidMount(){}

    //addStream = this.addStream.bind(this);
    //refresh = this.refresh.bind(this);
    //clear = this.clear.bind(this);

  addStream(e){
    e.preventDefault();
    //e.persist();
    //console.log(e);
    
    var newstream = encodeURI( e.target.parentElement.elements.newstreaminput.value );
    e.target.parentElement.elements.newstreaminput.value = "";
    var newStreamElement = document.createElement("LI");
    var targetwidth = this.state.width+"";
    console.log(targetwidth);
    var streamHTML = (
    <div className="streamDiv">
      <div className="toolbar">
        <button onClick={this.removeStream} value="remove">Remove</button>
        <button onClick={this.refreshSingle} value="refresh">Refresh</button>
      </div>
      <iframe src={"https://www.facebook.com/plugins/post.php?href=" + newstream + "&show_text=true&width=" + targetwidth } width={targetwidth} height="600" style={{border:'none', backgroundColor:'white'}} frameBorder="0"  scrolling="no" allow="encrypted-media; autoplay"></iframe>
    </div>
      );

    ReactDOM.render(streamHTML,newStreamElement);
    
    var list = document.getElementsByClassName("streamlist")[0];
    list.insertBefore(newStreamElement, list.childNodes[0]);
    
  }

  removeStream(e){
    e.preventDefault();
    var target = e.target.parentElement.parentElement.parentElement;
    document.getElementsByClassName("streamlist")[0].removeChild(target);
  }

  refreshSingle(e){
    e.preventDefault();
    var target = e.target.parentElement.parentElement.getElementsByTagName('iframe')[0];
    console.log(target);
    target.src = target.src;
  }

  clear(e){
    e.preventDefault();
    //e.persist();
    //console.log(e);
  }

  render() {
    return (
      <div className="container">        
          <AddForm  addStream={this.addStream} 
                    refresh={this.refresh}
                    clear={this.clear}
          />
          <StreamList width = {this.state.width}
                               />

      </div>
    );
  }//render
}//class

// ========================================

ReactDOM.render(<MultiView />, document.getElementById("root"));